angular.module('betoo')
  .controller('LeadsController', function ($http)
              {
                  var vm = this;

                  vm.send = function (form)
                  {
                      form.$invalid ? showValidationErrors(form) : storeLead(form);
                  };

                  function showValidationErrors(form)
                  {
                      form.email.$setDirty();
                      form.message.$setDirty();
                  }

                  function storeLead()
                  {
                      vm.submitting = true;
                      vm.showError = false;

                      $http.post(vm.url, generatePostBody(form))
                        .success(function (data, status, headers, config)
                                 {
                                     vm.submitting = false;
                                     data.response.success ? showSuccess() : showError();
                                 })
                        .error(function (data, status, headers, config)
                               {
                                   vm.submitting = false;
                                   showError();
                               });
                  }

                  function generatePostBody()
                  {
                      return {
                          email:     vm.email,
                          telephone: vm.telephone,
                          message:   vm.message
                      };
                  }

                  function showSuccess()
                  {
                      vm.showSuccess = true;
                  }

                  function showError()
                  {
                      vm.showError = true;
                  }
              });
